import { Component, OnInit } from '@angular/core';
import { fadeAnimation, sectionExpansion } from '../animations';


@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css'],
   animations: [
  	fadeAnimation,
  	sectionExpansion
  ]
})


export class HowItWorksComponent implements OnInit {

	// global nav
	navListVisible = false;
	iframeWidth = 650;
	iframHeight = 337;
	mobileStatus : boolean = false;
	hiw_active = 'recorded';
	section_open = [
		true,false,false,false,false
	]

	constructor() { }


	ngOnInit() {
		this.mobileStatus = window.innerWidth > 650 ? false : true;
	}

	toggleSection(section) {
		this.section_open[section] = !this.section_open[section];
	}

	onResize($event) {
		this.mobileStatus = $event.target.innerWidth > 650 ? false : true;
	}


	toggleNavList() {
    	this.navListVisible = !this.navListVisible;
	}


	getiFrameWidth() : string {
		if (window.innerWidth >= 600)
			return '640px';
		else if (window.innerWidth > 400 && window.innerWidth <= 600)
			return '400px';
		else if (window.innerWidth <= 400)
			return '270px';
	}

	getiFrameHeight() : string {
		if (window.innerWidth >= 600)
			return '360px';
		else if (window.innerWidth > 400 && window.innerWidth <= 600)
			return '225px';
		else if (window.innerWidth <= 400)
			return '152px';
	}

	getWHMobileHeaderVisible() : boolean {
		return window.innerWidth <= 650 ? true : false;
	}

	toggleInstructions(target) {
		if (target == 'recorded' && this.hiw_active != 'recorded')
			this.hiw_active = 'recorded';
		else if (target == 'video' && this.hiw_active != 'video')
			this.hiw_active = 'video';
	}


	copyDonateLink() {
		const selBox = document.createElement('textarea');
	    selBox.style.position = 'fixed';
	    selBox.style.left = '0';
	    selBox.style.top = '0';
	    selBox.style.opacity = '0';
	    selBox.value = 'https://whyhunger.org/SongAidDonate/';
	    document.body.appendChild(selBox);
	    selBox.focus();
	    selBox.select();
	    document.execCommand('copy');
	    document.body.removeChild(selBox); 
	    alert('Donation Link copied to clipboard!');
	}

}
