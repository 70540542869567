import { Component, OnInit } from '@angular/core';
import { fadeAnimation, sectionExpansion } from '../animations';
import config from './config.json';


@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
	animations: [
		fadeAnimation,
		sectionExpansion
	]
})


export class HomeComponent implements OnInit {


	compilation_data = config;
	section_open = [
		true,false,false,false,false,false
	]

	mobile = false;


	constructor() { }

	
	ngOnInit() {
		this.mobile = window.innerWidth > 768 ? false : true;
	}

	onResize($event) {
		if (window.innerWidth > 768) {
			this.mobile = false;
		} else {
			this.mobile = true;
		}
	}


	toggleSection(section) {
		this.section_open[section] = !this.section_open[section];
	}

	notifyParticipate() {
		this.scrollTo('participate');
	}

	scrollTo(id) {
		const element = document.getElementById(id);
		const y = element.getBoundingClientRect().top + window.pageYOffset - 80;
		window.scrollTo({top: y, behavior: 'smooth'});
	}


	getiFrameWidth() : string {
		return '100%';
	}


	getiFrameHeight() : string {
		if (window.innerWidth >= 1050)
			return '300px';
		else if (window.innerWidth < 1050 && window.innerWidth >= 1000) 
			return '320px';
		else if (window.innerWidth < 1000 && window.innerWidth >= 700) 
			return '460px';
		else if (window.innerWidth < 700 && window.innerWidth >= 650 ) 
			return '400px';
		else if (window.innerWidth < 650 && window.innerWidth >= 600) 
			return '360px';
		else if (window.innerWidth < 600 && window.innerWidth >= 550) 
			return '300px';
		else if (window.innerWidth < 550) 
			return '220px';

	}


	getDisplayWidth() : number {
		return window.innerWidth;
	}

	copyDonateLink(target) {
		const selBox = document.createElement('textarea');
	    selBox.style.position = 'fixed';
	    selBox.style.left = '0';
	    selBox.style.top = '0';
	    selBox.style.opacity = '0';
		switch (target) {
			case 'wh':
				selBox.value = 'https://whyhunger.org/SongAidDonate/';
				break;
			case 'naacp':
				selBox.value = 'https://secure.actblue.com/donate/naacp-1';
				break;
		}
	    document.body.appendChild(selBox);
	    selBox.focus();
	    selBox.select();
	    document.execCommand('copy');
	    document.body.removeChild(selBox); 
	    alert('Donation Link copied to clipboard!');
	}

}
