import {
  trigger,
  state,
  animate,
  transition,
  style,
  query,
  stagger
} from '@angular/animations';


export const fadeAnimation = trigger('fadeAnimation', [
  // The '* => *' will trigger the animation to change between any two states
  transition(
    ':enter', 
    [
      style({ opacity: 0 }),
      animate('0.5s ease-in-out', style({ opacity: 1 }))
    ]
  )
]);


export const sectionExpansion = trigger('sectionExpansion', [
  state('open', style({
      'max-height': '2000px',
    })),
    state('closed', style({
      'max-height': '0px',
    })),
    transition('open => closed', [
      animate('0.5s ease-in-out')
    ]),
    transition('closed => open', [
      animate('1.2s ease-in')
    ])
]);



