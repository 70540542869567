import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-grid',
  templateUrl: './company-grid.component.html',
  styleUrls: ['./company-grid.component.css']
})
export class CompanyGridComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
