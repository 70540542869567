import { Component, OnInit } from '@angular/core';
// import * as iframeLightbox from 'iframe-lightbox';
import * as basicLightbox from 'basiclightbox'


@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})


export class TestComponent implements OnInit {

	lightBox : boolean = false;

	constructor() { }


	ngOnInit() {
	 //  	[].forEach.call(document.getElementsByClassName("iframe-lightbox-link"), function (el) {
		//   el.lightbox = new IFrameLightbox(el);
		// });
		const instance = basicLightbox.create(`
		    <iframe src="https://www.youtube.com/embed/Nw-sFXSDYAs?autoplay=0" width="100%" height="100%" frameborder="0"></iframe>
		`)

		// instance.show();
	}

	toggleLightbox() {
		this.lightBox = !this.lightBox;
		console.log(this.lightBox);
	}	

}
