import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { AdvisorsComponent } from './advisors/advisors.component';


const routes: Routes = [
  // { path: '' , redirectTo: '/siena-sings', pathMatch: 'full' },
  { path: '' , component: HomeComponent },
  { path: 'advisors' , component: AdvisorsComponent },
  { path: 'participate' , component: HowItWorksComponent },
  
  { path: '**' , redirectTo: '/'},
  // { path: '**' , component : NotFoundComponent },

];

const routerOptions: ExtraOptions = {
  // scrollPositionRestoration: 'enabled',
  // anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  // enableTracing: true,
  // scrollOffset: [0, -64],
};


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [
  	RouterModule
  ]
})


export class AppRoutingModule { }
