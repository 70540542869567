import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import config from './config.json';


@Component({
  selector: 'app-artist-grid',
  templateUrl: './artist-grid.component.html',
  styleUrls: ['./artist-grid.component.css']
})


export class ArtistGridComponent implements OnInit {

    artist_data = config.artists;
    @Output() participateEvent = new EventEmitter();
  
    constructor() { }

  
  
    ngOnInit() {
      console.log(this.artist_data);
    }

    notifyParticipate() {
        this.participateEvent.emit();
    }

}
