import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { fadeAnimation, sectionExpansion } from './animations';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
  	fadeAnimation,
  	sectionExpansion
  ]
})


export class AppComponent {
  
	// global nav
	navListVisible = false;
	iframeWidth = 650;
	iframHeight = 337;
	mobileStatus : boolean = false;
	hiw_active = 'recorded';
	section_open = [
		true,false,false,false
	]
	spotlight_active = false;
	spotlight_msg = 'Go live today and/or join us on July 4th during SongAid’s Worldwide Live Event for Equality and Justice.';
	

	constructor(private router:Router) {}


	ngOnInit() {
		this.mobileStatus = window.innerWidth > 650 ? false : true;
		 this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
	}

	toggleSection(section) {
		this.section_open[section] = !this.section_open[section];
	}

	onResize($event) {
		this.mobileStatus = $event.target.innerWidth > 650 ? false : true;
	}


	toggleNavList() {
    	this.navListVisible = !this.navListVisible;
	}


	getiFrameWidth() : string {
		if (window.innerWidth >= 600)
			return '640px';
		else if (window.innerWidth > 400 && window.innerWidth <= 600)
			return '400px';
		else if (window.innerWidth <= 400)
			return '270px';
	}

	getiFrameHeight() : string {
		if (window.innerWidth >= 600)
			return '360px';
		else if (window.innerWidth > 400 && window.innerWidth <= 600)
			return '225px';
		else if (window.innerWidth <= 400)
			return '152px';
	}

	getWHMobileHeaderVisible() : boolean {
		return window.innerWidth <= 650 ? true : false;
	}

	toggleInstructions(target) {
		if (target == 'recorded' && this.hiw_active != 'recorded')
			this.hiw_active = 'recorded';
		else if (target == 'video' && this.hiw_active != 'video')
			this.hiw_active = 'video';
	}

	scrollTo(id) {
		// const elmnt = document.getElementById(id);

		const element = document.getElementById(id);
		const y = element.getBoundingClientRect().top + window.pageYOffset - 80;

		window.scrollTo({top: y, behavior: 'smooth'});

		// elmnt.scrollIntoView({behavior: "smooth", block: blockTarget, inline: "nearest"});
	}

	miniNavScrollTo(target) {
		this.toggleNavList();
		this.scrollTo(target);
	}

}
